<template>
  <div class="loyalty-details">
    <div v-if="currentBirthdayBonus" class="loyalty-details__active loyalty-details__row">
      <div class="loyalty-details__active-border-overlay" />
      <div class="loyalty-details__active-bg-overlay" />

      <loyalty-tag v-bind="currentBirthdayBonus.tagData" />
      <span class="loyalty-details__row-name">{{ currentBirthdayBonus.name }}</span>
      <span class="loyalty-details__active-label">
        {{ getContent(globalComponentsContent, defaultLocaleGlobalComponentsContent, 'loyalty.currentLevelLabel') }}
      </span>
      <span class="loyalty-details__row-value">{{ currentBirthdayBonus.bonus }}</span>
    </div>

    <div v-for="bonus in nextBirthdayBonuses" :key="bonus.order" class="loyalty-details__row">
      <loyalty-tag v-bind="bonus.tagData" />
      <span class="loyalty-details__row-name">{{ bonus.name }}</span>
      <span class="loyalty-details__row-value">{{ bonus.bonus }}</span>
    </div>
  </div>
</template>

<script lang="ts" setup>
  const { getContent, levelNameWithoutNumber } = useProjectMethods();
  const { globalComponentsContent, defaultLocaleGlobalComponentsContent } = useGlobalStore();
  const loyaltyStore = useLoyaltyStore();
  const { loyaltyLevels, loyaltyAccount } = storeToRefs(loyaltyStore);

  const birthdayBonusListContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.loyalty?.birthdayBonusList
    : globalComponentsContent?.loyalty?.birthdayBonusList || [];

  const tagsContent = defaultLocaleGlobalComponentsContent
    ? defaultLocaleGlobalComponentsContent.loyalty?.tags
    : globalComponentsContent?.loyalty?.tags || [];

  const getBirthdayBonusContentData = (order: string): { bonusValue: string; loyaltyLevels: string[] } | undefined => {
    return birthdayBonusListContent.find(birthdayBonusContent => birthdayBonusContent.loyaltyLevels.includes(order));
  };

  const getTagData = (
    order: string
  ): { topColor: string; bottomColor?: string; loyaltyLevels: string[] } | undefined => {
    return tagsContent.find(tag => tag.loyaltyLevels.includes(order));
  };

  const currentBirthdayBonus = computed(() => {
    if (!loyaltyLevels.value.length || !loyaltyAccount.value || !birthdayBonusListContent.length) return;

    const birthdayBonusContentData = getBirthdayBonusContentData(String(loyaltyAccount.value?.currentLevel.order));
    const tagData = getTagData(String(loyaltyAccount.value?.currentLevel.order));

    return {
      name: levelNameWithoutNumber(loyaltyAccount.value.currentLevel.name),
      bonus: birthdayBonusContentData?.bonusValue,
      tagData,
    };
  });

  const nextBirthdayBonuses = computed(() => {
    if (!loyaltyLevels.value.length || !loyaltyAccount.value || !birthdayBonusListContent.length) return [];

    const list: {
      order: number;
      name: string;
      tagData: { topColor?: string; bottomColor?: string };
      bonus: string;
    }[] = [];

    loyaltyLevels.value.forEach(levelData => {
      if (levelData.order <= (loyaltyAccount.value?.currentLevel.order ?? 100)) return;

      const birthdayBonusContentData = getBirthdayBonusContentData(String(levelData.order));

      if (
        currentBirthdayBonus.value?.bonus === birthdayBonusContentData?.bonusValue ||
        list.some(listItem => listItem.bonus === birthdayBonusContentData?.bonusValue)
      ) {
        return;
      }

      const tagContentData = getTagData(String(levelData.order));

      list.push({
        order: levelData.order,
        name: levelNameWithoutNumber(levelData.name),
        tagData: { topColor: tagContentData?.topColor, bottomColor: tagContentData?.bottomColor },
        bonus: birthdayBonusContentData?.bonusValue || '',
      });
    });

    return list;
  });
</script>

<style src="~/assets/styles/components/loyalty/details.scss" lang="scss" />
